// From https://github.com/domchristie/humps/issues/51#issuecomment-425113505
/* eslint-disable complexity */ /* eslint-disable max-statements */ import { camelize, decamelize } from 'humps';
function convertKeys(object, conversion) { const converter = { camelize, decamelize }; if (object && !(object instanceof File) && !(object instanceof Blob)) {
    if (object instanceof Array) {
        return object.map(item => convertKeys(item, conversion));
    }
    if (object instanceof FormData) {
        const formData = new FormData();
        for (const [key, value] of object.entries()) {
            formData.append(converter[conversion](key), value);
        }
        return formData;
    }
    if (typeof object === 'object') {
        return Object.keys(object).reduce((acc, next) => ({ ...acc, [converter[conversion](next)]: convertKeys(object[next], conversion) }), {});
    }
} return object; }
export default convertKeys;
